<template>
    <div class="container content-width">
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("title.privacy_policy") }}</h1>
        </div>

        <Clause
            class="no-border"
            :heading="$t('clause.clause01.heading')"
            :html="$t('clause.clause01.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause02.heading')"
            :html="$t('clause.clause02.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause03.heading')"
            :html="$t('clause.clause03.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause04.heading')"
            :html="$t('clause.clause04.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause05.heading')"
            :html="$t('clause.clause05.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause06.heading')"
            :html="$t('clause.clause06.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause07.heading')"
            :html="$t('clause.clause07.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause08.heading')"
            :html="$t('clause.clause08.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause09.heading')"
            :html="$t('clause.clause09.html')"
        />
        <Clause
            class="no-border"
            :heading="$t('clause.clause10.heading')"
            :html="$t('clause.clause10.html')"
        />
        <Clause
            v-if="$i18n.locale == 'en'"
            class="no-border"
            :heading="$t('clause.clause11.heading')"
            :html="$t('clause.clause11.html')"
        />
    </div>
</template>
<script>
import Clause from "@/components/Clause"

export default {
    components: {
        Clause,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.privacy.title"),
            description: this.$i18n.t("meta.privacy.description"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    i18n: {
        messages: {
            en: {
                clause: {
                    clause01: {
                        heading: "1. Introduction",
                        html: `
                    <p class="ts-quiet">Your privacy is important to us. It is ROKKODE INC.'s policy to respect your privacy regarding any information we may collect from you.</p>
                    <p class="ts-quiet">This privacy policy (“Policy”) explains how ROKKODE INC. (“We”) uses the personal data you provide when you use Stork Mobile services (“Services”) including the website <a class="ts-underline" href="https://storkmobile.com" target="_blank">https://storkmobile.com</a> (“Site”) and the application <a class="ts-underline" href="https://app.storkmobile.com" target="_blank">https://app.storkmobile.com</a> (“App”).</p>
                    <p class="ts-quiet">By using Stork Mobile Services, you recognize and accept the Policy outlined in the following.</p>
                    `,
                    },
                    clause02: {
                        heading: "2. Personal Data We Collect And Process",
                        html: `
                    <p class="ts-quiet">We may collect and process your Personal Data for the following purposes:</p>
                    <ul class="ts-quiet">
                        <li>Provision of telecommunication services and goods;</li>
                        <li>Provision of maintenance and support;</li>
                        <li>Provision of personalized contents and services;</li>
                        <li>Payment and transactions;</li>
                        <li>Analysis of sales, usage, effectiveness of measures, improvements, quality;</li>
                        <li>Provision of information (marketing) on our products, services, campaigns; and</li>
                        <li>Conducting occasional surveys.</li>
                    </ul>
                    <p class="ts-quiet">We may collect and process the following data:</p>
                    <ul class="ts-quiet">
                        <li>Contact information (such as your name, email address, etc.);</li>
                        <li>Communication devices (such as model name, serial number etc. of the devices you use);</li>
                        <li>Communication history (such as communication start & end time, data usage, etc.);</li>
                        <li>Location information (such as the SIM’s Cell-ID information); and</li>
                        <li>Information from the web browser (such as IP address, using and connection of web pages).</li>
                    </ul>
                    `,
                    },
                    clause03: {
                        heading: "3. Disclosure Of Personal Data To Third Parties",
                        html: `
                    <p class="ts-quiet">Personal Data collected and processed for the purposes listed above will only be disclosed to third parties with your consent.</p>
                    <p class="ts-quiet">However, we may disclose your Personal Data to third parties in order to provide you with the services you request. In these cases, we will only select third parties that are able to process Personal Data properly with respect to safety measures and with respect to the confidentiality and integrity of your Personal Data. We will implement appropriate supervision of such third parties’ processing of your Personal Data.</p>
                    <p class="ts-quiet">We may also disclose your Personal Data to third parties when required by law or court order.</p>
                    <p class="ts-quiet">Furthermore, we may compile statistics about your use of our services in order to further develop and improve Stork Mobile Services. These statistics are only used in aggregate and anonymized form.</p>
                    <p class="ts-quiet">Except for the above cases, we will not disclose your Personal Data to third parties.</p>
                    `,
                    },
                    clause04: {
                        heading: "4. Period For Which The Personal Data Will Be Stored",
                        html: `
                    <p class="ts-quiet">We will only collect and store your Personal Data until we have completed processing of such data for the above stated purposes. Hereafter, we will erase any Personal Data about you to ensure your privacy.</p>
                    `,
                    },
                    clause05: {
                        heading: "5. Management Of Your Personal Data",
                        html: `
                    <p class="ts-quiet">We will take and implement necessary and appropriate security measures to prevent the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to your Personal Data.</p>
                    <p class="ts-quiet">In order to ensure proper handling of personal information, we will conduct necessary education and training for its employees.</p>
                    <p class="ts-quiet">If we entrust your Personal Data to third parties in order to provide you with the requested goods and services, we will ensure that such third parties also take and implement necessary and appropriate security measures to prevent the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to your Personal Data. We will implement appropriate supervision of such third parties.</p>
                    `,
                    },
                    clause06: {
                        heading: "6. Right Of Access",
                        html: `
                    <p class="ts-quiet">You may at any given time request access to the Personal Data that we have collected and processed about you by contacting us via post or e-mail. For the purpose of verifying your identity and ensuring proper documentation, we recommend that you contact us via post or e-mail.</p>
                    <p class="ts-quiet">Please note that we may choose not to give access if your interest in having access to such Personal Data is found to be overridden by essential considerations of public interests, including in particular public security or if there is a risk of seriously hindering the proper implementation of our business.</p>
                    <p class="ts-quiet">Please find our contact information below.</p>
                    <p class="ts-quiet">ROKKODE INC.<br />
                    Toei Bldg. 6F, 6-1-8 Motomachi-dori, Chuo-ku, Kobe, Japan 650-0022<br />
                    Email: <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a></p>
                    `,
                    },
                    clause07: {
                        heading: "7. Other Rights",
                        html: `
                    <p class="ts-quiet">You may at any time object to our processing of your Personal Data. If your objection is justified, we will no longer process your Personal Data.</p>
                    <p class="ts-quiet">You may request that we rectify, erase or block Personal Data that is inaccurate or misleading or in any other way processed in violation of law or regulations.</p>
                    <p class="ts-quiet">We will make every reasonable effort to notify any third party to whom Personal Data may have been disclosed of any rectification, erasure or blocking carried out in accordance with your request.</p>
                    <p class="ts-quiet">You may, at any given time, withdraw your consent to our processing of Personal Data concerning you.</p>
                    <p class="ts-quiet">If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p>
                    <p class="ts-quiet">To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us or opt-out using the opt-out facilities provided in the communication.</p>
                    `,
                    },
                    clause08: {
                        heading: "8. International Transfers of Personal Data",
                        html: `
                    <p class="ts-quiet">Your Personal Data may in some instances be transferred to, processed in and stored at destinations outside Japan. By submitting your personal data, you explicitly consent to this transfer, storing or processing outside of Japan.</p>
                    <p class="ts-quiet">We will take all necessary steps reasonably to ensure that your Personal Data is processed securely and in accordance with this Privacy Policy.</p>
                    `,
                    },
                    clause09: {
                        heading: "9. Cookies",
                        html: `
                    <p class="ts-quiet">We use “cookies” to collect information about you and your activity across our Site and App. Please refer to our <a class="ts-underline" href="/cookie-policy">Cookie Policy</a> for more information.</p>
                    `,
                    },
                    clause10: {
                        heading: "10. Limits Of Our Policy",
                        html: `
                    <p class="ts-quiet">Our Site and App may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
                    `,
                    },
                    clause11: {
                        heading: "11. Changes To This Policy",
                        html: `
                    <p class="ts-quiet">At our discretion, we may change our privacy policy to reflect current acceptable practices. Your continued use of the Services after any changes to this policy will be regarded as acceptance of our practices around privacy and personal data.</p>
                    <p class="ts-quiet">If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.</p>
                    `,
                    },
                },
            },
            ja: {
                clause: {
                    clause01: {
                        heading: "1. 個人情報取り扱いに関する基本方針",
                        html: `
                    <p class="ts-quiet">ROKKODE 株式会社（以下、「当社」という。）は、電気通信事業法、個人情報の保護に関する法律、電気通信事業における個人情報保護に関するガイドライン等の業務主管庁が定めた事業分野別ガイドライン、その他関連する法令等を遵守するとともに、Stork Mobileブランドで提供する電気通信サービスに関して個人情報を以下の通り取り扱います。</p>
                    `,
                    },
                    clause02: {
                        heading: "2. 個人情報の取得方法",
                        html: `
                    <p class="ts-quiet">個人情報はウェブサイト <a class="ts-underline" href="https://storkmobile.com" target="_blank">https://storkmobile.com</a>（以下、「サイト」という。）およびアプリケーション <a class="ts-underline" href="https://app.storkmobile.com" target="_blank">https://app.storkmobile.com</a>（以下、「アプリ」という。）経由で、法令を遵守して適法に取得します。</p>
                    `,
                    },
                    clause03: {
                        heading: "3. 個人情報の利用目的",
                        html: `
                    <p class="ts-quiet">当社は、電気通信サービスの提供等にあたり、次の利用目的の達成に必要な範囲内で個人情報を利用します。</p>
                    <ul class="ts-quiet">
                        <li>電気通信サービスの提供・保守、サービス・ネットワーク等の設備・機器・ソフトウェアの障害・不具合・事故発生時の調査・対応等アフターサービス提供のため</li>
                        <li>パーソナライズされたコンテンツやサービス提供のため</li>
                        <li>お客様からの相談(ご意見・ご要望・お問い合わせ)等への対応のため</li>
                        <li>利用状況の分析、各種施策実施のための分析および当該施策の効果測定、新サービス企画のための分析、サービス品質改善・応対サービス向上のための分析その他各種分析・調査の実施のため</li>
                        <li>不正利用・不払いの発生防止および発生時の調査・対応のため</li>
                        <li>商品・サービス・キャンペーン・イベント等のご案内、広告の表示・配信のため</li>
                    </ul>
                    `,
                    },
                    clause04: {
                        heading: "4. 取得する個人情報",
                        html: `
                    <p class="ts-quiet">当社は、次の個人情報を上記に記載の利用目的の達成に必要な範囲内で取得します。</p>
                    <ul class="ts-quiet">
                        <li>お名前</li>
                        <li>メールアドレス</li>
                        <li>機種名、製造番号等のご利用の通信機器本体に関する情報</li>
                        <li>ご利用の通信機器本体の位置情報に関する情報</li>
                        <li>通信開始/終了時刻・通信時間等の通信履歴に関する情報</li>
                        <li>ブラウザからの情報（IPアドレス、ブラウザの種類とバージョン、訪問したWebページ、訪問日時、滞在時間等）</li>
                    </ul>
                    `,
                    },
                    clause05: {
                        heading: "5. 個人情報の管理",
                        html: `
                    <p class="ts-quiet">当社は、個人情報への不正なアクセスの防止、個人情報の漏えい・滅失・き損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講じます。<br />
                    当社は、個人情報の適正な取り扱いの確保のため、従業者に対し、必要な教育研修を実施します。<br />
                    当社は、個人情報の取り扱い業務の全部または一部を委託する場合があります。この場合、当社は、個人情報を適正に取り扱うと認められるものを選定し、委託契約において、安全管理措置、秘密保持、再委託の条件その他の個人情報の取り扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。</p>
                    `,
                    },
                    clause06: {
                        heading: "6. 個人情報の第三者提供について",
                        html: `
                    <p class="ts-quiet">当社は、基本原則として本人の同意を得ずに個人情報を第三者に提供しません。提供先・提供情報内容を特定したうえで、本人の同意を得た場合に限り提供します。ただし、以下の場合は、本人の同意なく個人情報を提供することがあります。</p>
                    <ul class="ts-quiet">
                        <li>法令に基づく場合</li>
                        <li>生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
                        <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</li>
                        <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
                    </ul>
                    `,
                    },
                    clause07: {
                        heading: "7. 個人情報の訂正等",
                        html: `
                    <p class="ts-quiet">当社は、お客様本人から自己に関する個人情報の訂正、追加、削除、利用停止または第三者提供の停止の求めがあった場合には、遅滞なく調査を行います。その結果、当該個人情報に関し、内容が事実でない、保存期間を経過している、その他取り扱いが適正でないと認められるときは、遅滞なく訂正等を行います。個人情報の訂正等のお申し出は、<a href="/contact">問い合わせフォーム</a>を利用し、ご申告願います。</p>
                    `,
                    },
                    clause08: {
                        heading: "8. 個人情報に関するお問い合わせおよび開示等手続きについて",
                        html: `
                    <p class="ts-quiet">お客様ご本人またはその代理人が、個人情報に関して、利用目的の通知、開示および第三者への提供の停止を要求される場合の連絡先は、以下となります。</p>
                    <p class="ts-quiet">ROKKODE 株式会社　「ストークモバイルお客様情報開示申請」受付窓口<br />
                    Email: <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a></p>
                    `,
                    },
                    clause09: {
                        heading: "9. Cookieについて",
                        html: `
                    <p class="ts-quiet">当社は、サイトおよびアプリでCookieを利用します。Cookieに関するポリシーは <a class="ts-underline" href="/cookie-policy">Cookie Policy</a> をご確認ください。</p>
                    `,
                    },
                    clause10: {
                        heading: "10. 外部リンクについて",
                        html: `
                    <p class="ts-quiet">当社の運営するサイトとアプリは、外部へのリンクを含んでいる場合があります。それら外部サイトにおける個人情報の取り扱いに関しては、当社は責任を負いません。<br />
本プライバシーポリシーは当社のサイトとアプリ内でのみ適用されます。</p>
                    `,
                    },
                },
            },
        },
    },
}
</script>
